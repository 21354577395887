<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/project-team' }">
        <app-i18n code="entities.projectTeam.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.projectTeam.view.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.projectTeam.view.title"></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-project-team-view-toolbar
        v-if="record"
      ></app-project-team-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-relation-to-many
          :label="fields.teamMember.label"
          :readPermission="fields.teamMember.readPermission"
          :navigatePermission="fields.teamMember.navigatePermission"
          :url="fields.teamMember.viewUrl"
          :value="presenter(record, 'teamMember')"
        ></app-view-item-relation-to-many>

        <app-view-item-relation-to-many
          :label="fields.projectManager.label"
          :readPermission="fields.projectManager.readPermission"
          :navigatePermission="fields.projectManager.navigatePermission"
          :url="fields.projectManager.viewUrl"
          :value="presenter(record, 'projectManager')"
        ></app-view-item-relation-to-many>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectTeamViewToolbar from '@/modules/project-team/components/project-team-view-toolbar.vue';
import { ProjectTeamModel } from '@/modules/project-team/project-team-model';

const { fields } = ProjectTeamModel;

export default {
  name: 'app-project-team-view-page',

  props: ['id'],

  components: {
    [ProjectTeamViewToolbar.name]: ProjectTeamViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'projectTeam/view/record',
      loading: 'projectTeam/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'projectTeam/view/doFind',
    }),

    presenter(record, fieldName) {
      return ProjectTeamModel.presenter(record, fieldName);
    },
  },
};
</script>
